<template>
  <v-card>
    <v-card-title>
      {{ $t("msh.aside.devices") }}
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        :loading-text="this.$t('msh.loading')"
        item-key="deviceId"
        :loading="isLoading('/msh.service.v1.BuildingManager/GetFloors')"
      ></v-text-field>
    </v-card-title>

    <v-form id="triggerForm" ref="form" v-model="valid" lazy-validation>
      <v-dialog v-model="dialog" max-width="500px">
        <v-card>
          <v-card-title>
            <span class="text-h5">
              {{ $t("msh.devices.recording.triggerRecording") }}
            </span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="recording.label"
                    name="label"
                    :rules="recording.nameRules"
                    :label="this.$t('msh.devices.recording.label.label')"
                    :error-messages="recording.error.label"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="recording.duration"
                    name="duration"
                    :rules="recording.durationRules"
                    :label="this.$t('msh.devices.recording.label.duration')"
                    :error-messages="recording.error.duration"
                  ></v-text-field>
                </v-col>
                <!-- <v-col cols="12" sm="12" v-if="recording.result"> -->
                <!-- <v-alert v-for="triggerResult in recording.triggerResults" v-bind:key="triggerResult.deviceId" :type="triggerResult.alertType" class="text-center">
                        {{ triggerResult }}
                      </v-alert> -->
                <!-- </v-col> -->
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close">
              {{ $t("msh.devices.recording.cancel") }}
            </v-btn>
            <v-btn color="blue darken-1" text @click="triggerRecording">
              {{ $t("msh.devices.recording.trigger") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>

    <v-dialog v-model="confirmUnprovisionDialog" max-width="400px">
      <v-card>
        <v-card-title>
          <span class="text-h5"> Confirm unprovisionning </span>
        </v-card-title>

        <v-card-text v-if="confirmUnprovisionDevice">
          Device {{ confirmUnprovisionDevice.label }} will be unprovisionned
          from {{ confirmUnprovisionDevice.structure_name }}
        </v-card-text>

        <v-alert text type="error" v-if="confirmError">
          An error occurred when unprovisionning this device.
        </v-alert>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeConfirmUnprovision">
            {{ $t("msh.devices.recording.cancel") }}
          </v-btn>
          <v-btn color="blue darken-1" text @click="confirmUnprovision">
            Unprovision
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-data-table
      v-model="selected"
      show-select
      item-key="deviceId"
      :headers="headers"
      :items="floor_devices"
      :search="search"
      :custom-filter="customSearch"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <!-- Missing if for other moonshot email .... -->
          <v-btn
            color="primary"
            @click="displayTriggerDialog()"
            :disabled="triggerDisabled"
          >
            {{ $t("msh.devices.recording.triggerRecording") }}
          </v-btn>

          <v-btn @click="navigateToProvisionSteps" class="ml-5">
            Provision a new device
          </v-btn>
        </v-toolbar>
      </template>

      <template v-slot:item.isOnline.value="{ item }">
        <span :class="item.isOnline.value == false ? 'text--error' : ''">{{
          $t("msh.devices.online." + item.isOnline.value)
        }}</span>
      </template>

      <template v-slot:item.actions="{ item }">
        <!-- status 1 is Running show cancel/stop menu -->
        <template v-if="item.rawStorage && item.rawStorage.status == 1">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn text v-bind="attrs" v-on="on">
                <span class="pl-1">
                  {{ $t("msh.devices.recording.stop") }}
                </span>
                <v-icon v-if="isDeviceInAbortList(item.deviceId)"
                  >fas fa-circle-notch fa-spin</v-icon
                >
              </v-btn>
            </template>
            <v-list>
              <v-list-item link>
                <v-list-item-title @click.stop="stopRecording(item)">
                  <v-icon small> fas fa-stop </v-icon>
                  <span class="pl-1">
                    {{ $t("msh.devices.recording.stopRecording") }}
                  </span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item link>
                <v-list-item-title @click.stop="cancelRecording(item)">
                  <v-icon small> fas fa-ban </v-icon>
                  <span class="pl-1">
                    {{ $t("msh.devices.recording.cancelRecording") }}
                  </span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-spacer />
        </template>
        <template>
          <v-btn v-if="isManager()" @click="displayConfirmUnprovision(item)"
            >Unprovision</v-btn
          >
        </template>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import store from "@/store";
import {
  FLOOR_FORCE_UPDATE,
  FLOOR_GET_DEVICES_REQUEST,
  DEVICE_STOP_RECORDING_REQUEST,
  DEVICE_TRIGGER_RECORDING_REQUEST,
  DEVICE_CANCEL_RECORDING_REQUEST,
  DEVICE_UNPROVISION_DEVICE_REQUEST,
} from "@/store/constants";
import {
  DEVICE_TRIGGER_RECORDING_SUCCEEDED,
  DEVICE_TRIGGER_RECORDING_ERROR,
  DEVICE_STOP_RECORDING_SUCCEEDED,
  DEVICE_STOP_RECORDING_ERROR,
  DEVICE_CANCEL_RECORDING_SUCCEEDED,
  DEVICE_CANCEL_RECORDING_ERROR,
  DEVICE_UNPROVISION_DEVICE_SUCCEEDED,
  DEVICE_UNPROVISION_DEVICE_ERROR,
} from "@/events";
import JwtService from "@/services/jwt.service";
import urlsafeService from "@/services/urlsafe.service";
import router from "@/router";
import { bus } from "@/main.js";

export default {
  data() {
    return {
      dialog: false,
      valid: true,
      confirmUnprovisionDialog: false,
      confirmUnprovisionDevice: null,
      confirmError: false,
      selected: [],
      recording: {
        label: "",
        duration: "",
        triggerResults: [],
        result: null,
        currentAbortList: [],
        error: {
          message: "",
          label: "",
          duration: "",
          alertType: "",
        },
        nameRules: [
          (v) => !!v || this.$t("msh.validation.required.name"),
          (v) =>
            (v && !v.includes("_")) ||
            this.$t("msh.validation.format.nameUnderscore"),
          (v) =>
            (v && !v.includes(" ")) ||
            this.$t("msh.validation.format.nameSpace"),
        ],
        durationRules: [
          (v) => !!v || this.$t("msh.validation.required.duration"),
          (v) =>
            /^\+?(0|[1-9]\d*)$/.test(v) ||
            this.$t("msh.validation.format.durationPositive"),
        ],
      },
      search: "",
      headers: [
        {
          text: this.$t("msh.dashboard.incidents.floor"),
          align: "start",
          sortable: true,
          value: "floor_name",
        },
        {
          text: this.$t("msh.dashboard.incidents.appartment"),
          value: "structure_name",
          sortable: true,
        },
        { text: this.$t("msh.devices.label"), value: "label" },
        { text: this.$t("msh.devices.online.title"), value: "isOnline.value" },
      ],
    };
  },
  methods: {
    /**
     * Custor search that support offline online special search, default to regular full test search otherwise
     */
    customSearch(value, search, item) {
      if (value != null && search != null && search.includes("offline")) {
        // Check if multiple with ','
        if (item.isOnline.value == false) {
          return true;
        }
      }
      if (value != null && search != null && search.includes("online")) {
        if (item.isOnline.value == true) {
          return true;
        }
      }
      // if not with custom search do the regular search
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().includes(search)
      );
    },
    /**
     * Close the dialog window and erase the data
     */
    close() {
      this.dialog = false;
    },
    resetRecording() {
      this.$refs.form.resetValidation();
      this.recording.label = "";
      this.recording.duration = "";
      this.recording.triggerResults = [];
      this.recording.result = null;
      this.recording.currentAbortList = [];
      this.recording.error.message = "";
      this.recording.error.label = "";
      this.recording.error.duration = "";
      this.recording.error.alertType = "";
    },
    /**
     * Show the dialog and prepare the data for the trigger command
     */
    displayTriggerDialog() {
      this.resetRecording();
      this.dialog = true;
    },
    /**
     * Dispatch the command to the store
     * Will have the update from the event bus
     */
    triggerRecording() {
      let validateResult = this.$refs.form.validate();

      if (validateResult) {
        let recordingRequest;
        if (this.selected && this.selected.length > 1) {
          recordingRequest = {
            accountId: this.getAccountId,
            deviceIds: this.selected.map((x) => x.deviceId),
            duration: this.recording.duration * 60,
            label: this.recording.label,
          };
        } else {
          recordingRequest = {
            deviceId: this.selected[0].deviceId,
            duration: this.recording.duration * 60,
            label: this.recording.label,
          };
        }
        store.dispatch(DEVICE_TRIGGER_RECORDING_REQUEST, recordingRequest);
      }
    },
    /**
     * Stop an ongoing recording for the provided deviceId
     */
    stopRecording(device) {
      this.recording.currentAbortList.push(device.deviceId);
      store.dispatch(DEVICE_STOP_RECORDING_REQUEST, device.deviceId);
    },
    /**
     * Cancel an ongoing recording for the provided deviceId
     */
    cancelRecording(device) {
      this.recording.currentAbortList.push(device.deviceId);
      store.dispatch(DEVICE_CANCEL_RECORDING_REQUEST, device.deviceId);
    },
    /**
     * Check if the provided deviceID has a pending request to stop/cancel the recording
     */
    isDeviceInAbortList(deviceId) {
      return this.recording.currentAbortList.includes(deviceId);
    },
    /**
     * Set the device for the unconfirm and trigger the dialog
     */
    displayConfirmUnprovision(device) {
      // Will need to get structure for this device
      this.confirmUnprovisionDevice = device;
      this.confirmUnprovisionDialog = true;
    },
    /**
     * Close the confirm dialog window
     */
    closeConfirmUnprovision() {
      this.confirmUnprovisionDialog = false;
      this.confirmUnprovisionDevice = null;
    },
    /**
     * Confirm unprovisionning a device from a structure
     */
    confirmUnprovision() {
      let provRequest = {
        structureId: urlsafeService.generateUrlsafeKey(
          this.confirmUnprovisionDevice.deviceId,
          ["Device"]
        ),
        serialNumber: this.confirmUnprovisionDevice.serialNumber,
      };
      store.dispatch(DEVICE_UNPROVISION_DEVICE_REQUEST, provRequest);
    },
    /**
     * Easier method to know if current user is a manager
     */
    isManager() {
      return JwtService.isManager();
    },
    /**
     * Navigate to provision screen
     */
    navigateToProvisionSteps() {
      router.push({
        name: "provisionSteps",
        params: { buildingId: router.currentRoute.params.buildingId },
      });
    },
  },
  /**
   * Section require to show and hide the dialog window
   */
  watch: {
    dialog(val) {
      val || this.close();
    },
    confirmUnprovisionDialog(val) {
      val || this.close();
    },
  },
  mounted() {
    const email = JwtService.getEmail();
    // Check if current user is part of @moonshothealth.com
    if (email && email.endsWith("@moonshothealth.com")) {
      // only show the recording section for moonshot emails
      this.headers.push({
        text: this.$t("msh.devices.recording.status"),
        value: "actions",
        sortable: false,
      });
    }
    const searchQuery = router.currentRoute.query.q;
    if (searchQuery) {
      this.search = searchQuery;
    }

    // Event bus events for the trigger operation
    bus.$on(DEVICE_TRIGGER_RECORDING_ERROR, (error) => {
      this.recording.alertType = "error";
      this.recording.result = error;
    });
    bus.$on(DEVICE_TRIGGER_RECORDING_SUCCEEDED, (triggerResponse) => {
      this.recording.alertType = "info";
      if (
        triggerResponse &&
        triggerResponse.deviceStartResponsesList &&
        triggerResponse.deviceStartResponsesList.length > 0
      ) {
        this.recording.result = this.$tc(
          "msh.devices.recording.startedOn",
          triggerResponse.deviceStartResponsesList.length
        );
      } else {
        this.recording.result = this.$t("msh.devices.recording.started");
      }
      // closing the popup after 2 seconds we displayed the recording started
      setTimeout(() => {
        this.dialog = false;
      }, 2000);
    });

    // Stop recording event
    bus.$on(DEVICE_STOP_RECORDING_SUCCEEDED, (deviceId) => {
      this.recording.currentAbortList = this.recording.currentAbortList.filter(
        (value, index) => {
          if (value == deviceId) {
            return false;
          }
          return true;
        }
      );
    });
    bus.$on(DEVICE_STOP_RECORDING_ERROR, (deviceId) => {
      this.recording.currentAbortList = this.recording.currentAbortList.filter(
        (value, index) => {
          if (value == deviceId) {
            return false;
          }
          return true;
        }
      );
    });
    // Cancel recording event
    bus.$on(DEVICE_CANCEL_RECORDING_SUCCEEDED, (deviceId) => {
      this.recording.currentAbortList = this.recording.currentAbortList.filter(
        (value, index) => {
          if (value == deviceId) {
            return false;
          }
          return true;
        }
      );
    });
    bus.$on(DEVICE_CANCEL_RECORDING_ERROR, (deviceId) => {
      this.recording.currentAbortList = this.recording.currentAbortList.filter(
        (value, index) => {
          if (value == deviceId) {
            return false;
          }
          return true;
        }
      );
    });

    // Bus on unprovision succeed
    bus.$on(DEVICE_UNPROVISION_DEVICE_SUCCEEDED, (serialNumber) => {
      if (
        this.confirmUnprovisionDevice &&
        this.confirmUnprovisionDevice.serialNumber == serialNumber
      ) {
        store.dispatch(
          FLOOR_FORCE_UPDATE,
          router.currentRoute.params.buildingId
        );
        this.confirmUnprovisionDialog = false;
        this.confirmError = false;
        this.confirmUnprovisionDevice = null;
      }
    });
    // Bus on unprovision errored
    bus.$on(DEVICE_UNPROVISION_DEVICE_ERROR, (serialNumber) => {
      if (
        this.confirmUnprovisionDevice &&
        this.confirmUnprovisionDevice.serialNumber == serialNumber
      ) {
        // Display generic message for now
        this.confirmError = true;
      }
    });
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapState({
      floor_devices: (state) => state.floors.floor_devices,
    }),
    ...mapGetters(["isLoading", "getAccountId"]),
    /**
     * Change enabled status on the trigger recording button
     */
    triggerDisabled() {
      if (this.selected.length > 0) {
        return false;
      }
      return true;
    },
  },
  async beforeRouteEnter(to, from, next) {
    //const buildingid = store.getters.getCurrentBuilding.getBuildingId();
    store.dispatch(FLOOR_GET_DEVICES_REQUEST, to.params.buildingId);
    next();
  },
};
</script>
